import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProductSimilar from "../components/product-similar";

import DataFlexispot from "../data/flexispot-h2w";
import DataImperioLift from "../data/imperio-lift";
import DataEadjust from "../data/eadjust"

const IndexPage = ({ data }) => {

    const googleAnalyticsEventFunctionFlexispot = function (e, val = "Flexispot") {
        //e.preventDefault()
        trackCustomEvent({
            category: "Affiliate Link",
            action: "Click",
            label: val,
        })
        console.log("click event: " + val)
    };

    const googleAnalyticsEventFunctionImperio = function (e, val = "Imperio Lift") {
        //e.preventDefault()
        trackCustomEvent({
            category: "Affiliate Link",
            action: "Click",
            label: val,
        })
        console.log("click event: " + val)
    };

    const googleAnalyticsEventFunctionEadjust = function (e, val = "Eadjust") {
        //e.preventDefault()
        trackCustomEvent({
            category: "Affiliate Link",
            action: "Click",
            label: val,
        })
        console.log("click event: " + val)
    };

    return (
        <Layout>
            <SEO title="Stehschreibtisch.info - Höhenverstellbare Schreibtische im Vergleich" />
            <div className="jumbotron">
                <div className="container">
                    <h1 className="display-4">Höhenverstellbare Schreibtische</h1>
                    <p className="lead">Arbeiten im Stehen ist gut für deine Gesundheit, dein Wohlbefinden und deine Produktivität.</p>
                    <hr className="my-4" />
                    <p>Wir helfen dir den richtigen Stehschreibtisch für dich zu finden.</p>
                    <a className="btn btn-primary" href="vergleich.php" role="button"><span className="badge badge-light">8</span> Stehschreibtische im Vergleich</a>
                </div>
            </div>

            <div className="container">
                <h2>Vorteile</h2>
                <br />
                <div className="card-deck">
                    <div className="card border-success text-center">
                        <h5 className="card-header text-success"><span role="img" aria-label="Gesundheit">💚</span> Gesundheit</h5>
                        <div className="card-body">
                            <p className="card-text">
                                Wer zu viel sitzt, stirbt früher<sup><a href="http://www.thelancet.com/journals/lancet/article/PIIS0140-6736(16)30370-1/abstract" target="_blank">1</a></sup>.
                        Arbeitest du abwechselnd im Stehen und Sitzen, beugst du Krankheiten vor und verbesserst gleichzeitig Körperhaltung und Kraft.

                    </p>
                        </div>
                    </div>
                    <div className="card border-warning text-center">
                        <h5 className="card-header text-warning"><span role="img" aria-label="Wohlbefinden">😊</span> Wohlbefinden</h5>
                        <div className="card-body">
                            <p className="card-text">
                                Bewegung macht glücklich! Im Stehen bewegst du dich automatisch mehr.
                        Du wirst weniger verspannt und am Ende des Tages energiegeladener sein.</p>
                        </div>
                    </div>
                    <div className="card border-primary text-center">
                        <h5 className="card-header text-primary"><span role="img" aria-label="Produktivität">🚀</span> Produktivität</h5>
                        <div className="card-body">
                            <p className="card-text">
                                Wer sich wohl fühlt kann besser und konzentrierter Arbeiten.
                                Du wirst mehr Aufgaben schneller und besser erledigen können.
                    </p>
                        </div>
                    </div>
                </div>
                <br />

                <h2>Empfehlungen</h2>
                <br />
                <div className="card-deck mb-5">
                    <ProductSimilar
                        name={DataFlexispot.name}
                        rating={DataFlexispot.rating}
                        reviewsAmount={DataFlexispot.reviewsAmount}
                        brand={DataFlexispot.brand}
                        internalLink={DataFlexispot.internalLink}
                        gaEventFunction={googleAnalyticsEventFunctionFlexispot}
                        affiliateLink={DataFlexispot.affiliateLink}
                        image={data.flexispotImage.childImageSharp.fluid}
                    />
                    <ProductSimilar
                        name={DataImperioLift.name}
                        rating={DataImperioLift.rating}
                        reviewsAmount={DataImperioLift.reviewsAmount}
                        brand={DataImperioLift.brand}
                        internalLink={DataImperioLift.internalLink}
                        gaEventFunction={googleAnalyticsEventFunctionImperio}
                        affiliateLink={DataImperioLift.affiliateLink}
                        image={data.imperioImage.childImageSharp.fluid}
                    />
                    <ProductSimilar
                        name={DataEadjust.name}
                        rating={DataEadjust.rating}
                        reviewsAmount={DataEadjust.reviewsAmount}
                        brand={DataEadjust.brand}
                        internalLink={DataEadjust.internalLink}
                        gaEventFunction={googleAnalyticsEventFunctionEadjust}
                        affiliateLink={DataEadjust.affiliateLink}
                        image={data.eadjustImage.childImageSharp.fluid}
                    />
                </div>
                <br />
            </div>
        </Layout>
    )
}

export const otherProductImage = graphql`
  fragment otherProductImage on File {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export default props => (
    <StaticQuery
        query={graphql`
            query {
                flexispotImage: file(relativePath: { eq: "image-flexispot-h2w.jpg" }) {
                    ...otherProductImage
                }
                imperioImage: file(relativePath: { eq: "image-imperio-lift.jpg" }) {
                    ...otherProductImage
                }
                eadjustImage: file(relativePath: { eq: "image-eadjust.jpg" }) {
                    ...otherProductImage
                }
            }
      `}
        render={data => <IndexPage data={data} {...props} />}
    />
)
